import React from "react";
import { Skeleton } from "primereact/skeleton";

import { Divider } from "primereact/divider";

export default function AlphaJourneyEmpty({ data }) {
    const chartHeights = "250px";

        let isPositive = true
    if (data.predict_details.predict_details.previsao === "Baixa") {
        isPositive = false
    }

    // Verifica se a jornada está arquivada com base em 'data_fim'
    const isArchived = !!data.metrics.data_fim;


    return (

        <div className="alphaDetails">
            <div className="header mt-5 mb-2 flex align-items-center justify-content-between">
                <div className="identification">
                    <div className="text-4xl font-bold">
                        {`${data.metrics.nome_ativo} - ${data.metrics.tipo_plano}`}
                    </div>
                    <div className="text-2xl">
                        {data.metrics.alpha_description} ({data.metrics.alpha_code})
                    </div>
                </div>

                {!isArchived && (
                    <div className="cta">
                        <div className="flex gap-3">
                            <div className={`flex flex-column text-center p-1 pl-4 pr-4 br-1 ` + (isPositive ? "bg-blue-100" : "bg-orange-100") }>
                                <div className="previsao font-bold">
                                    Previsão para o próximo dia útil
                                </div>
                                <div className="previsao font-bold text-2xl">
                                    {data.predict_details.predict_details.previsao}
                                </div>
                                <div className="contratos">
                                {data.predict_details.predict_details.quant_contratos + (data.predict_details.predict_details.quant_contratos > 1 ? " contratos" : " contrato")}  
                                </div>
                                <div className="contratos">
                                    Atualizado em: {data.predict_details.predict_details.last_date_mini}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <Divider align="center">
                <span className="p-tag">Jornada Alpha Sem Retorno</span>
            </Divider>
            <div className="br-1 mt-3 mb-3">
                <Skeleton width="100%" height="65px" />
            </div>
            <div className="flex flex-column w-full gap-3 mb-3">
                <>
                    <Skeleton width="100%" height={chartHeights} />
                    <Skeleton width="100%" height={chartHeights} />
                </>
            </div>
        </div>
    );
}
